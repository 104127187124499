import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Loader from '../components/Loader';


export default function Outlet() {

  const [outlets,setOutlets]=useState(null)

  const [cities,setCities]=useState('')
  
  const [selectedCity,setSelectedCity]=useState('')


  console.log(selectedCity,"selectedCity")
  useEffect(() => {
    const Outlets = async () => {
      try {

        


        if(selectedCity !== ''){
          
          const postData = { city: selectedCity };
          const response = await api.post(`${process.env.REACT_APP_BASE_URL}outlet`,postData);
          setOutlets(response?.data?.data?.data);
        }

        else{
          const response = await api.post(`${process.env.REACT_APP_BASE_URL}outlet`);
          setOutlets(response?.data?.data?.data);
        }
      
        

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    Outlets();

    const Cities = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}city-list`);
      
        
        // setOutlets(response?.data?.data?.data);

        
        setCities(response?.data?.data)

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    Cities();

  }, [selectedCity]);




  




  const handleCityChange=(e)=>{
    setSelectedCity(e.target.value)
  }
  
  
  return (
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          <NavBar />
        
      <div className="about_wrapper outlets_wrapper">
      <div className="sec_container">
        <div className="about_inner">
        <h1>Our Outlets</h1>
<div className="select_city">
  <span>Select Your City</span> 
  <select name="" id="" onChange={(e) => handleCityChange(e)}>
  <option value="">Select a city</option>
  {cities && cities.map((item, index) => (
    <option key={index} value={item?.city}>{item?.city}</option>
  ))}
</select>

</div>
  
          <div className="outlets">
            
          {outlets !== null ?
          outlets.map((item,id)=>(
<div className='outlets_inner' key={id}>
  <div className='name'>{item?.name}</div>
  <div className='address'>{item?.address1} {item?.address2} {item?.address3} - {item?.pincode}</div>
  <div className='phone'> 
  {item?.phone1} {item?.phone2 && `, ${item.phone2}`} {item?.mobile && `, ${item.mobile}`}
  </div>
  
  </div>
))

  :<Loader/>        
        }
        </div>
        </div>
      </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
