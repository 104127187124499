import React from 'react'

import { Link } from 'react-router-dom'
import TagBar from '../TagBar'
import Header from '../Header'
import NavBar from '../NavBar'
import Footer from '../Footer'

export default function MyOrders() {
  return (
    <div className="wrapper account_wrapper">
      <div className="tag_line_outer">
        <TagBar />
      </div>
      <div className="header_outer">
        <div className="sec_container">
          <Header/>
        </div>
      </div>
      
        <NavBar />
        
      <div className="account_section_outer">
        <h1>My Orders</h1>
        <div className="sec_container">
        <div className="account_section_inner">
        <div>
        <h1>Order Id #0000</h1>
        <ul className="">
            <li style={{display:"flex",alignItems:"center",margin:"10px 0"}}>
            <img src='/assets/images/gluten_free_biscuit.png' alt="" width="80px"/>
            <span style={{maxWidth:"200px",fontSize:"16px",lineHeight:"1.2",margin:"0 5px"}}>GLUTEN FREE MULTIGRAIN COOKIES2</span>
            

            </li>
            <b style={{fontWeight:"600",color:"#000",fontSize:"14px"}}>Order Status : Shipped</b>
            <Link to="/orderstatus" style={{color:"#5291E7",display:"block",textDecoration:"underline",fontWeight:"500",marginTop:"10px",fontSize:"14px",textAlign:"right"}}>Track Details</Link>
        </ul>
        </div>
        
        
        </div>
        </div>
        </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
