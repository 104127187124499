

import { configureStore } from '@reduxjs/toolkit';
import { accessToken, cartListing, couponCode, loggedInUser, productListing } from './slices';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cartData');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const loadLoggedState=()=>{
  try {
    const isLogged = localStorage.getItem('isLoggedUser');

    
    
    if (isLogged === null) {
      return undefined;
    }
    // return localStorage.getItem('isLoggedUser');
    return JSON.parse(isLogged);

  } catch (error) {
    return undefined;
  }
  
}

const loadAccessTokenState=()=>{
  try {
    const isaccess = localStorage.getItem('isAccessToken');
    
    if (isaccess === null) {
      return undefined;
    }
    
    return isaccess;

  } catch (error) {
    return undefined;
  }
  
}


const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cartData', serializedState);
  } catch (error) {
    
  }
};

const saveLoggedStatus=(state)=>{
  try {
    
    const loggedState = state

    if(loggedState !== undefined){
      localStorage.setItem('isLoggedUser', loggedState);
    }
    
  } catch (error) {
    
  }
}

const saveAccessStatus=(state)=>{
  try {
    
    const accessState = state
    if(accessState !== undefined){
      localStorage.setItem('isAccessToken', accessState);
    }
    
  } catch (error) {
    
  }
}



const store = configureStore({
  reducer: {
    listingData: productListing.reducer,
    couponData:couponCode.reducer,
    cartData: cartListing.reducer,
    isLoggedInUser:loggedInUser.reducer,
    isAccessToken:accessToken.reducer,
  },
  preloadedState: {
    cartData: loadState(),
    isLoggedInUser: loadLoggedState(),
    isAccessToken:loadAccessTokenState(),

  },
});

store.subscribe(() => {
  saveState(store.getState().cartData);
  saveLoggedStatus(store.getState().isLoggedInUser)
  saveAccessStatus(store.getState().isAccessToken)
});

export default store;





// store.js before

// import { configureStore } from '@reduxjs/toolkit';
// import {  cartListing, productListing } from './slices';

// const store = configureStore({
//   reducer: {
//     listingData: productListing.reducer,
//     cartData: cartListing.reducer,
//   },
// });

// export default store;



