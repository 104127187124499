import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
const RatingDisplay = ({ ratingFromApi }) => {
  const totalStars = 5;

  const stars = Array.from({ length: totalStars }, (_, index) => {
    // const starClass =
    //   index < ratingFromApi ? 'fa-solid fa-star' : 'fa-regular fa-star';
let starClass;
    // if (ratingFromApi >= 0.1 && ratingFromApi <= 0.5) {
    //   starClass = index < Math.floor(ratingFromApi) ? 'fa-solid fa-star' : 'fa-regular fa-star';
    //   if (index === Math.floor(ratingFromApi)) {
    //     starClass += ' fa-star-half-stroke';
    //   }
    // } else {
    //   starClass = index < ratingFromApi ? 'fa-solid fa-star' : 'fa-regular fa-star';
    // }


  if (ratingFromApi >= 0 && ratingFromApi <= 5) {
    const roundedRating = Math.round(ratingFromApi * 2) / 2; // Round to nearest half
    starClass =
      index < roundedRating ? 'fa-solid fa-star' : 'fa-regular fa-star';

    if (index === Math.floor(roundedRating) && roundedRating % 1 !== 0) {
      starClass += ' fa-star-half-stroke';
    }
  } else {
    starClass = index < ratingFromApi ? 'fa-solid fa-star' : 'fa-regular fa-star';
  }

    // Use a unique key for each star element
    return <i key={`star-${index}`} className={`${starClass}`}></i>;
  });

  return <div>{stars}</div>;
};

export default RatingDisplay;