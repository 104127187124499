import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';


export default function SearchBar() {

const [serchBarResult,setSearchBarResult]=useState('');

const handleSearchChange=(e)=>{
  setSearchBarResult(e.target.value)
}


const navigate = useNavigate()



const { search } = useLocation();
// const searchParams = new URLSearchParams(search);
const searchParams = useMemo(() => new URLSearchParams(search), [search]);




const [searchData, setSearchData] = useState(
  searchParams.get("search") || null
);

useEffect(() => {
  setSearchData(searchParams.get("search") || null);

  setSearchBarResult(searchParams.get("search") || null)
}, [ searchParams]);

console.log(searchData,"searchParams")
//   const [searchData, setSearchData] = useState(
//     searchParams.get("search") || null
//   );



const searchBtn=()=>{
  navigate(`/products?search=${serchBarResult}`);
  // setSearchBarResult(searchData)
};



const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      navigate(`/products?search=${serchBarResult}`);
  }
};


  return (
    <div className='search_bar_outer'>
        <div className="">
            <input type="text" placeholder='Search' value={serchBarResult} onChange={handleSearchChange}  onKeyDown={handleKeyDown} />            
        </div>

        <div className="btn">
            <i className="fa-solid fa-magnifying-glass" onClick={searchBtn}></i>
        </div>
        
    </div>
  )
}
