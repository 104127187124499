import React, { useEffect, useState } from "react";
import ReactImageMagnify from 'react-image-magnify';
const ProductSlider = ({ prodDetail }) => {
const [sliderImg, setSliderImg] = useState(null);
const [currentIndex, setCurrentIndex] = useState(-1);
  console.log(currentIndex)
  useEffect(() => {
    if (prodDetail?.image) {
      setSliderImg(`${process.env.REACT_APP_FOR_API_URL}${prodDetail?.image}`);
    }
  }, [prodDetail?.image]);

  const handleSetImage = (image) => {
    setSliderImg(image);
  };
  
  // console.log(currentIndex)
  // const handleNextImage = () => {
  //   const nextIndex = (currentIndex + 1) % (prodDetail.other_prd_images.length + 1);
  //   if (nextIndex === prodDetail.other_prd_images.length) {
  //     setSliderImg(`${process.env.REACT_APP_FOR_API_URL}${prodDetail.image}`);
  //   } else {
  //     setSliderImg(`${process.env.REACT_APP_FOR_API_URL}${prodDetail.other_prd_images[nextIndex]}`);
  //   }
  //   setCurrentIndex(nextIndex);
  // };
  
  const handleNextImage = (direction) => {
    setCurrentIndex(prevIndex => {
      let nextIndex;
      if (direction === "next") {
        nextIndex = (prevIndex + 1) % (prodDetail.other_prd_images.length + 1);
      } else {
        nextIndex = (prevIndex - 1 + prodDetail.other_prd_images.length + 1) % (prodDetail.other_prd_images.length + 1);
      }
  
      if (nextIndex === prodDetail.other_prd_images.length) {
        setSliderImg(`${process.env.REACT_APP_FOR_API_URL}${prodDetail.image}`);
      } else {
        setSliderImg(`${process.env.REACT_APP_FOR_API_URL}${prodDetail.other_prd_images[nextIndex]}`);
      }
      return nextIndex;
    });
  };
  
    
  return (
    <>
      <div className="big_img">
{sliderImg && 
      <ReactImageMagnify {...{
    smallImage: {
        alt: 'fijifoj',
        isFluidWidth: true,
        src: sliderImg,
    },
    largeImage: {
        src: sliderImg,
        isFluidWidth: true,
        width: 1200,
        height: 1800
    }
}}
 />

}

<div onClick={() => handleNextImage("next")} className="next_arrow">{">"}</div>
<div onClick={() => handleNextImage("prev")}  className="prev_arrow">{"<"}</div>

 {/* <div onClick={handleNextImage} style={{fontSize:"30px",cursor:"pointer"}}>{">"}</div> */}
        {/* <img src={sliderImg} alt="" /> */}
      </div>
      <div className="other_images">
        {prodDetail?.other_prd_images.map((item, id) => (
          <div key={id}>
            {prodDetail?.image &&
            <img
              src={`${process.env.REACT_APP_FOR_API_URL}${item}`}
              alt=""
              width="100px"
              onClick={() => handleSetImage(`${process.env.REACT_APP_FOR_API_URL}${item}`)}
            />
            }
          </div>
        ))}
        <div>
        {prodDetail?.image && 
          <img src={`${process.env.REACT_APP_FOR_API_URL}${prodDetail?.image}`} alt="" onClick={()=>{handleSetImage(`${process.env.REACT_APP_FOR_API_URL}${prodDetail?.image}`)}} />
        }
      
        </div>
      </div>
    </>
  );
};

export default ProductSlider;
