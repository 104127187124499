import React from 'react'

export default function FirstOrder(props) {
    const{heading,subheading}=props
  return (
    <div className='first_order_inner'>
        <div className="heading">{heading}</div>
        <p>{subheading}</p>
        {/* <div className='form_box'>
            <div className="form_field">
                <input type="email" placeholder='Email Address'/>
            </div>
            <div className="btn">
                <span>Reveal coupon</span>
            </div>
        </div> */}
    </div>
  )
}
