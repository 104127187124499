import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer_inner">
      <div className="foot_top">
        <div className="foot_logo">
        <img src="/assets/images/logocookies.png" alt="" />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
        </div>
        <div className="foot_nav">
          <div>
            <span>QUICK LINK</span>
            <ul>
              <li>
                <Link to="/">Track Your Order</Link>
              </li>
              <li>
                <Link to="/products">Shop All</Link>
              </li>
              <li>
                <Link to="/about">About US</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/cart">Cart</Link>
              </li>
              {/* <li>
                <Link to="/">Support</Link>
              </li> */}
              {/* <li>
                <Link to="/">Reward</Link>
              </li> */}
              <li>
                <Link to="/bloglisting">Blog</Link>
              </li>
              <li>
                <Link to="/faqs">Shipping Faq</Link>
              </li>
            </ul>
          </div>
          <div>
          <span>CONTACT US</span>
            <ul>
              <li>
                <Link to="/">info@info.com</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="foot_bottom">
        <div>
          <span>© 2022 NOGLU All Rights Reserved. </span>
        </div>
        <ul>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/refundpolicy">Refund Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
