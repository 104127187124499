import React, { useState, useEffect } from 'react';
import {  googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import {  useDispatch, useSelector } from 'react-redux';
import {  setAccessToken, setIsLoggedInUser } from '../store/slices';
import { Link } from 'react-router-dom';
import api from '../axios/api';

const GoogleLoginPage = () => {
    
const [ user, setUser ] = useState(null);

const dispatch = useDispatch();

const isLoggedIn = useSelector((store)=>store.isLoggedInUser)





const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});

  

  useEffect(
      () => {
          if (user) {
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      }
                  }).then((res) => {
                      dispatch(setIsLoggedInUser(true))
                      return res;
                      
                  }).then((res)=>{
                    if(res?.data){
                        ProductListingData(res?.data)
                    }

                  })
                  .catch((err) => console.log(err));
          }
      },
    // eslint-disable-next-line  
      [ user ,dispatch]
  );

  const ProductListingData = async (response) => {
    try {
      const postData = { email: response?.email,name:response?.name,family_name:response?.family_name,google_id:response?.id,image:response?.picture,domain_name:response?.hd };

       response = await api.post(`${process.env.REACT_APP_BASE_URL}login-with-google`,postData);
       
       
       dispatch(setAccessToken(response?.data?.token))
       

    } catch (error) {
      console.error('API error:', error);
    
    }
  };






  const logout=()=>{
    dispatch(setIsLoggedInUser(false))
    dispatch(setAccessToken(null))
    googleLogout();
    
}
      


    return (
      <>
          <div>
        
            {isLoggedIn?
            <ul>
                <li>
                My Account <i className="fa-solid fa-user" style={{marginLeft:"10px",fontSize:"23px"}}></i>
                <ul>
                    <li>
                    <Link to="/account" style={{display:'flex',alignItems:"center",gap:"2px"}}>
                    Manage Your Account
                    </Link>
                    </li>
                    <li>
                    <span onClick={logout}>Logout</span>
                    </li>
                </ul>
                
                </li>
                
            </ul>
                
            :
                <button onClick={() => login()} className='gmail_login_btn'>
                    <img src="/assets/images/googl-icon.png" alt="" style={{width:"18px"}}/>
                    Sign in with Google 
                </button>
            }

{/* <button onClick={() => login()} className='gmail_login_btn'>
                    <img src="/assets/images/googl-icon.png" alt="" style={{width:"18px"}}/>
                    Sign in with Google 
                </button> */}
             
        </div>
          </>
    )
}

export default GoogleLoginPage;