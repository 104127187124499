import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../components/homePage/HomePage";
import ProductListing from "../components/products/ProductListing";
import Cart from "../components/cart/Cart";
import CheckOut from "../components/cart/CheckOut";
import ProductDetail from "../components/products/ProductDetail";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Outlet from "../pages/Outlet";
import Recipes from "../pages/Recipes";
import BlogListing from "../pages/BlogListing";
import BlogDetail from "../pages/BlogDetail";
import Account from "../pages/Account";
import MyOrders from '../components/accounts/MyOrders'
import ChangePassword from '../components/accounts/ChangePassword'
import AddAddress from "../components/accounts/AddAddress";
import MyAddress from "../components/accounts/MyAddress";
import OrderStatus from "../components/accounts/OrderStatus";
import PhonePePayment from "../components/Payments/PhonePePayment";
// import PhonePePaymentNext from "../components/Payments/PhonePeNext";
import PaymentProcess from "../components/Payments/PaymentProcess";
import Terms from "../components/Terms";
import Privacy from "../components/Privacy";
import RefundPolicy from "../components/RefundPolicy";
import Faqs from "../components/Faqs";
import RecipesDetail from "../pages/RecipesDetail";
import OrderCompleted from "../components/cart/OrderCompleted";

// import { PrivateRoute } from "./router";
// import Announcements from "../Components/announcements";
// import LoginPage from "../Components/login";
// import AnnouncementDetail from "../Components/announcementDetail";
// import ProtectedRoute from "./ProtectedRoute";
// import NoPageFound from "../Components/NoPageFound";



// import NotFound from 'pages/notFound';
export default class AllRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Routes>

            {/* <Route element={<ProtectedRoute />}>
              <Route
                path={"/announcements"}
                element={
                  <Announcements />
                }
              />
            </Route> */}
            
            <Route path={"/"} element={<HomePage />} />
            <Route path={'/products'} element={<ProductListing/>}/>
            <Route path={'/cart'} element={<Cart/>}/>
            <Route path={'/checkout'} element={<CheckOut/>}/>
            <Route path={'/productdetail/:slug'} element={<ProductDetail/>}/>
            
            <Route path={'/about'} element={<About/>}/>
            <Route path={'/contact'} element={<Contact/>}/>
            <Route path={'/outlets'} element={<Outlet/>}/>
            <Route path={'/recipes'} element={<Recipes/>}/>
            <Route path={'/recipesdetail/:slug'} element={<RecipesDetail/>}/>
            <Route path={'/bloglisting'} element={<BlogListing/>}/>
            <Route path={'/blogDetail/:slug'} element={<BlogDetail/>}/>
            <Route path={'/contact'} element={<Contact/>}/>
            <Route path={'/account'} element={<Account/>}/>
            <Route path={'/orders'} element={<MyOrders/>}/>
            <Route path={'/changepassword'} element={<ChangePassword/>}/>
            <Route path={'/myaddress'} element={<MyAddress/>}/>
            <Route path={'/addaddress'} element={<AddAddress/>}/>
            <Route path={'/orderstatus'} element={<OrderStatus/>}/>
            <Route path={'/paymentgateway'} element={<PhonePePayment/>}/>
            <Route path={'/ordercompleted'} element={<OrderCompleted/>}/>
            {/* <Route path={'/paymentgatewaynext'} element={<PhonePePaymentNext/>}/> */}
            
            <Route path={'/paymentprocess/:slug'} element={<PaymentProcess/>}/>
            <Route path={'/terms'} element={<Terms/>}/>
            <Route path={'/privacy'} element={<Privacy/>}/>
            <Route path={'/refundpolicy'} element={<RefundPolicy/>}/>

            <Route path={'/faqs'} element={<Faqs/>}/>
            
            
          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}