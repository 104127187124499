import React from 'react'

import TagBar from '../TagBar'
import Header from '../Header'
import NavBar from '../NavBar'
import Footer from '../Footer'

export default function OrderStatus() {
  return (
    <div className="wrapper account_wrapper">
      <div className="tag_line_outer">
        <TagBar />
      </div>
      <div className="header_outer">
        <div className="sec_container">
          <Header/>
        </div>
      </div>
      
        <NavBar />
        
      <div className="account_section_outer">
        <h1>Track Your Order</h1>
        <div className="sec_container">
        
        <div className="order_status_outer">
      <div className="sec_container">
        <ul className="order_status_inner">
          <li className='active'>
            <span className='status'></span>
            {/* <img src='../assets/images/tick_icon.svg' alt="" /> */}
            <span >
              Ordered (Date)
            </span>
          </li>

          <li className=''>
            <span className='status'></span>
            {/* <img src='../assets/images/tick_icon.svg' alt="" /> */}
            <span >
              Packed & Shipping
            </span>
          </li>

          <li className=''>
            <span className='status'></span>
            {/* <img src='../assets/images/tick_icon.svg' alt="" /> */}
            <span >
              Product Shipped
            </span>
          </li>
          <li className=''>
            <span className='status'></span>
            {/* <img src='../assets/images/tick_icon.svg' alt="" /> */}
            <span >
              Item Delivered
            </span>
          </li>
          
        </ul>
      
        </div>
        
        
        </div>
        </div>
        </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
