import React, { useState } from "react";
import Header from "../Header";
import {  useSelector } from "react-redux";
import Footer from "../Footer";
import CartSteps from "./CartSteps";

// import { Link } from "react-router-dom";
import MyAddress from "../accounts/MyAddress";
import api from "../../axios/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


export default function Cart() {

  const couponData= useSelector((store)=>store.couponData)
  const cartData = useSelector((store) => store.cartData.items);
  const accessToken = useSelector((store) => store.isAccessToken);
  const [selectedAddress,setSelectedAddress]=useState(null)
  const [editAddress, setEditAddress] = useState(null);

  function calculateTotalPrice() {
    let TotalPrice = 0;
    cartData?.forEach((products) => {
      TotalPrice += Math.round(products?.qty * products?.price);
    });
    return TotalPrice;
  }

  
  let shippingCosts = 0 ;
  if(calculateTotalPrice()<500){
    shippingCosts = 50
      }
  // const [coupanCode,setCoupanCode]=useState('');





  function grandTotal(){
    let grandTotal = 0
    grandTotal = (shippingCosts + calculateTotalPrice()) - couponData.desc_amt;
    return grandTotal
  }
  

  


  
  

  console.log(cartData,"cartData")

  const cartDataList = cartData.map((item)=>(item.id))

  const placeOrder = async()=>{
    const postData={
      product_id:cartDataList,
      transaction_id:21,
      address_id:selectedAddress?.id,
      total_amount:calculateTotalPrice(),
      final_amount:grandTotal(),
      coupon_code:couponData?.coupon_code,
    }
    

    try{
      const response = await api.post(`${process.env.REACT_APP_BASE_URL}place-order`,postData,accessToken);

      if(response.status === 200){
        toast.success('Order Placed Successfully')

      }else{
        toast.error('Order Failed')
        
      }
    }catch (err){
      console.error('API error',err)

    }
  }


  return (
    <div className="wrapper cart_wrapper">
      
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      <div className="cart_section_outer">
      <CartSteps shoppingcartImg={'../assets/images/tick_icon.svg'} isCartPageActive={false} checkoutImg={'../assets/images/shop_cart_icon_active.svg'} isCheckoutActive={true} orderImg={'../assets/images/order_complete_white.svg'}/>
        <div className="sec_container">
          <div className="cart_section_inner">
          <Link to="/cart" style={{marginBottom:'10px',display:'block',color:'#ba1e11'}}><i className="fa-solid fa-chevron-left"  style={{color:'#ba1e11',fontWeight:600,fontSize:'15px',}}></i> Go Back</Link>
            {cartData.length !== 0 ? (
              <>
                <div className="cart_data_inner">
                  
                  <div>
                    <div className="heading_outer">
                      <span>Your Address </span>
                      </div>
                      <div className="address_outer">
                          <MyAddress selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} editAddress={editAddress} setEditAddress={setEditAddress}/>

                      </div>
                  </div>


                  <div className="total_box">
                    <div className="grand_total">
                      <span>Grand Total: </span>
                      <b> Rs.{calculateTotalPrice()}</b>
                    </div>
                    {/* <div className=" disc_price">
                      <span>Discount</span>
                      <b>Rs. {discountPrice} </b>
                    </div> */}
                    <div className="shipping_cost">
                      <span>Shipping Cost</span>
                      <b>Rs. {shippingCosts}</b>
                    </div>
                    {Object.keys(couponData).length !== 0 &&
                    <div className="coupon_disc">
                    <span>Coupon Discount</span>
                    <b style={{color:'green',fontWeight:'600'}}>- Rs. {couponData.desc_amt} </b>
                  </div>
}
                    {/* <div className="coupon_code">
                      <input type="text" value={coupanCode} onChange={(e)=>setCoupanCode(e.target.value)} placeholder="Coupon code"/>
                      <button type="submit" onClick={handleCoupon}>Apply Coupon</button>
                    </div> */}

                    {/* <span className="free_ship_msg">Get Free  <b style={{color:"#000"}}>Shipping</b> for orders over <b style={{color:"red"}}>Rs.500</b></span> */}

                    <div className="place_order" >
                    {/* <Link to="/paymentgateway">Place Order | Rs.{grandTotal()}</Link>  */}

                    {/* <Link to="/ordercompleted">Place Order | Rs.{grandTotal()}</Link> */}

                    
                    <span onClick={placeOrder}  className={!selectedAddress ? 'disabled' :''}>Place Order | Rs.{grandTotal()}</span>
                    


                  
                    </div>
                    {!selectedAddress && <div style={{color:'red',fontWeight:600}}>Select address to continue</div>}
                  </div>
                </div>
              </>
            ) : (
              <div className="no_products">
                <img src="/assets/images/No_products_found.png" alt="" />
                <span>
                  No Products <b>Found</b>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
