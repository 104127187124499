import React from 'react'
import SearchBar from './SearchBar'
import { Link}  from 'react-router-dom'
import { useSelector } from 'react-redux'
import GoogleLoginPage from './GoogleLoginPage'

export default function Header() {
  const cartData = useSelector((store)=>store.cartData.items)
  
  return (
    
        <div className="header_inner">
          <div className="logo">
          
          <Link to="/">
            <img src="/assets/images/logocookies.png" alt="" />
            </Link>
          </div>
          <div className="desk_search">
          <SearchBar/>
          </div>
          <div className="cart_outer">
            <div className='your_acc'>
            <GoogleLoginPage/>
            </div>
            <div className='cart_icon'>
              <Link to="/cart">
              <img src="/assets/images/cart_icon.svg" alt="" style={{width:"34px"}}/>
              <span>{cartData.length}</span>
              </Link>
            </div>
          </div>
        </div>
      
    
  )
}