import React from "react";

export default function Advantages() {
  return (
    <ul className="advantages_inner">
      <li>
        <div className="imgb">
          <img src="/assets/images/truck_icon.svg" alt="" />
        </div>
        <div className="text">
          <span>Delicious Cookie Delivery</span>
          <p>
            Satisfy your sweet cravings with our prompt cookie delivery service!
            We offer a variety of mouthwatering cookies delivered right to your
            doorstep. Enjoy free shipping on orders over $49!
          </p>
        </div>
      </li>
      <li>
        <div className="imgb">
          <img src="/assets/images/truck_icon.svg" alt="" />
        </div>
        <div className="text">
          <span>Secured Treat Transactions</span>
          <p>
            Our secure payment system ensures a worry-free checkout experience!
            Choose from various payment options, including debit, major credit
            cards, and even sweeten the deal with cryptocurrency.
          </p>
        </div>
      </li>
      <li>
        <div className="imgb">
          <img src="/assets/images/truck_icon.svg" alt="" />
        </div>
        <div className="text">
          <span>Premium Quality & Irresistible Prices</span>
          <p>
            Indulge in the finest quality cookies at unbeatable prices. At our
            cookie haven, we guarantee the perfect blend of taste and
            affordability. Treat yourself to a delightful experience!
          </p>
        </div>
      </li>
    </ul>
  );
}
