import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { addItem } from "../../store/slices";

// import { toast } from "react-toastify";

import Header from "../Header";
import NavBar from "../NavBar";

import RangeSlider from "./RangeFilter";
import api from "../../axios/api";

import Loader from "../Loader";
import { Link, useLocation } from "react-router-dom";
import Category from "./Category";
import Footer from "../Footer";
import { percentageDisc } from "../../utils";

export default function ProductListing() {
  const { search } = useLocation();


  // eslint-disable-next-line
  const searchParams = new URLSearchParams(search);
  const [searchData, setSearchData] = useState(
    searchParams.get("search") || null
  );
  const [productListing, setProductListing] = useState([]);
  const [rangevalue, setRangeValue] = useState([0, 10000]);
  const [categorySelected, setCategorySelected] = useState(null);

  // const dispatch = useDispatch();

  const ProductListingData = async (searchData) => {
    try {
      // const postData = { };

      const filterData = {
        start_price: rangevalue[0],
        end_price: rangevalue[1],
        catId: categorySelected,
        search: searchData
      };

      let response;
      // if (searchData !== null) {
        // response = await api.post(
        //   `${process.env.REACT_APP_BASE_URL}products`,
        //   postData
        // );


      //   setProductListing(response?.data?.products?.products);
      // } else {
        response = await api.post(
          `${process.env.REACT_APP_BASE_URL}products`,
          filterData
        );

        setProductListing(response?.data?.products?.products);
      // }

      // Handle the response data as needed, e.g., set it to state
    } catch (error) {
      console.error("API error:", error);
      // Handle the error, e.g., set an error state or show an error message
    }
  };



  useEffect(() => {
    ProductListingData(searchData);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    // eslint-disable-next-line
  }, [searchData]);

  useEffect(() => {
    setSearchData(searchParams.get("search") || null);
  }, [search, searchParams]);

  // console.log(searchData,"search")
  // const handleAddCart = (e, item) => {
  //   e.preventDefault();
  //   const newItem = {
  //     productimg: item.image,
  //     id: item.id,

  //     name: item.prd_name,
  //     qty: 1,
  //     msr_unit: item.msr_unit,
  //     unit: item.prd_qty,
  //     price: item.discounted_price,
  //   };
  //   dispatch(addItem(newItem));
  //   toast.success("Item Added Successfully",{toastId: "1"});;
  // };



  const handleFilterChanges = () => {

    ProductListingData(searchData);
  };

  const handleFilter = () => {
    document.body.classList.toggle('filter_active')
  }

  // const buyNow=(e,item)=>{
  //   e.preventDefault();

  //   console.log(item,"item")
  //   // navigate('')
  // }


  return (
    <div className="wrapper">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

      <NavBar />

      <div>
        <div className="products_listing_section">
          <div className="sec_container">
            <div className="products_listing_sec_inner">  
              <div className="filter_box">
                <div className="toggle_btn" onClick={handleFilter}>
                  <span><i className="fa-solid fa-xmark"></i>Close</span>
                </div>
                <div className="title">Filters </div>

                <div className="product_cat_btn">
                  <span className="filter_heading">PRODUCT CATEGORY</span>

                  <Category setCategorySelected={setCategorySelected} />
                </div>

                <div className="range_slider_outer">
                  <span className="filter_heading">FILTER BY PRICE</span>
                  <RangeSlider
                    rangevalue={rangevalue}
                    setRangeValue={setRangeValue}
                  />
                </div>

                <span onClick={handleFilterChanges} className="apply_btn">
                  Apply{" "}
                </span>
              </div>
              <div>
                <div className="title_outer" >
                  <div className="title">Shop </div>
                  <div className="toggle_btn" onClick={handleFilter} >
                    <span><i className="fa-solid fa-bars-staggered"></i>Filter</span>
                  </div>
                </div>

                <ul className="product_listing_ul">
                  {productListing?.data ? productListing?.data?.length !== 0 ? (
                    productListing?.data?.map((item, productId) => (
                      <li className="products_listing_li" key={productId}>
                        <Link to={`/productdetail/${item.slug}`}>
                          <div className="imgb">
                            <img
                              src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`}
                              alt=""
                            />
                          </div>

                          <div className="text">
                            <span>{item?.category}</span>
                            <div className="name">{item?.prd_name}</div>
                            <div className="star_reviews">
                              {item?.rating && (
                                <div>
                                  <i className="fa-solid fa-star"></i>
                                  {Number(item?.rating).toFixed(1)}/5
                                </div>
                              )}

                              {item?.total_review && (
                                <div>
                                  {item?.total_review} <span>Reviews</span>
                                </div>
                              )}
                            </div>

                            <div className="price_outer">
                              <div className="disc_price">
                                Rs. {item?.price}
                              </div>
                              <div className="price" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                Rs. {item?.discounted_price} <span style={{ fontSize: '12px', color: 'green', fontWeight: '500' }}>({percentageDisc(item?.price, item?.discounted_price)}% off)</span>
                              </div>
                            </div>


                            {/* <span
                              onClick={(e) => handleAddCart(e, item)}
                              className="add_cart"
                            >
                              Add to Cart
                            </span> */}

                            <span className="add_cart" style={{ pointerEvents: 'none' }}>
                              Buy Now
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))
                  ) : (
                    'No Products Found'
                  ) :
                    <Loader products={true} />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
