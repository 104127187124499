import React, { useEffect, useState } from 'react'
import api from '../../axios/api';

export default function Category({setCategorySelected}) {
    const [catDataList,setCatDataList]= useState('');

    const catList = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_BASE_URL}category`);
          setCatDataList(response?.data?.data)
          // Handle the response data as needed, e.g., set it to state
        } catch (error) {
          console.error('API error:', error);
          // Handle the error, e.g., set an error state or show an error message
        }
      };
    
        
        
    
    
    useEffect(()=>{
      catList();
    },[])
    

  return (
    <>
    {catDataList.length>0 &&
        <ul className="product_cat_ul">
          {catDataList?.map((item) => (
          <li key={item?.id}>
            <input type="radio" name="cat_list" id={item?.id} value={item?.name}
              onChange={(e)=>setCategorySelected(item?.id)}/>
            <label htmlFor={item?.id}>{item?.name}</label>
          </li>
          )
          )}
        </ul>
        }
        </>
  )
}
