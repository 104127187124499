import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export default function RangeFilter(props) {
const{rangevalue,setRangeValue}=props

return (
<>

<div className="range_slider_number">
  <div className='range_first'>
      Rs. {rangevalue[0]}
    </div>
    <div className='range_last'>
      Rs. {rangevalue[1]}
    </div>
    </div>
    <RangeSlider min={0} max={10000} step={100} value={rangevalue} onInput={setRangeValue} />
    

</>

);
}