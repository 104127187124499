import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import api from '../axios/api';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

export default function BlogDetail() {

  const [blogDetail, setBlogDetail] = useState(null);

  const { slug } = useParams();

  

  useEffect(() => {
    const BlogDetail = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BASE_URL}blog-details/${slug}`);
        setBlogDetail(response?.data?.data?.blog);
      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    BlogDetail();
    
      window.scrollTo({
             top: 0,
             left: 0,
             behavior: "smooth"
           });
   
  }, [slug]);

  
  return (
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          <NavBar />
        
      <div className="about_wrapper blog_detail_wrapper">
      <img src={`${process.env.REACT_APP_FOR_API_URL}${blogDetail?.image}`} alt="" />
      <div className="sec_container">
        <div className="about_inner blog_detail_inner">
          
          <h1>{blogDetail?.title}</h1>
          <p>{blogDetail?.description}</p>
        </div>
        </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
