import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Header from '../components/Header';

export default function About() {

  const [aboutUs,setAboutUs]=useState(null)
  useEffect(() => {
    const AboutUs = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}about-us`);
        setAboutUs(response?.data?.data);

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    AboutUs();

  }, [])
  
  return (
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          <NavBar />
        
      <div className="about_wrapper">
      
        {aboutUs && <img src={`${process.env.REACT_APP_FOR_API_URL}${aboutUs?.image}`} alt="" />}
            
            <div className="sec_container">
            <div className="about_inner">
            <h1>{aboutUs?.title}</h1>
            
            <p>{aboutUs?.description}</p>
            
        </div>
      </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
