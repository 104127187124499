import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

export default function BlogListing() {
  const [blogsList,setBlogList]=useState(null)
  

  useEffect(() => {
    const AboutUs = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}blog-list`);
        
        setBlogList(response?.data?.data?.blogs?.data);

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    AboutUs();

  }, [])

  
  return (
    
  
  
  
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

          <NavBar />
        
      <div className="about_wrapper">
      <div className="sec_container">
        <div className="about_inner blogs_inner">
        <h1>Our Blogs</h1>
          <div className="outlets ">
            
          {blogsList !== null ?
          blogsList.map((item,id)=>(
<div className='outlets_inner blog_box' key={id}>
<Link to={`/blogdetail/${item.slug}`}>
  <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
  
  <div className='name'>{item?.title}</div>
  
  </Link>
  </div>
))

  :<Loader/>        
        }
        </div>
        </div>
      </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )


  

}
