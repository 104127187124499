import React from 'react' 

import Slider from "react-slick";

import RatingDisplay from './RatingDisplay';
import { convertToLocalTime } from '../../utils';

export default function Testimonials({homeApiData}) {
var settings = {
dots: false,
infinite: true,
speed: 500,
arrows:true,
slidesToShow: 3,
slidesToScroll: 3,
responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    
  ]
};



// const testimonialsData=[
// {
//     id:"1",
// img:'/assets/images/logo.svg',
// name:'Vikki Star1',
// stars:1,
// desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
// postedDate:'January 15, 2023',


// },
// {
//     id:"2",
// img:'/assets/images/logo.svg',
// name:'Vikki Star2',
// stars:2,
// desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
// postedDate:'January 15, 2023',

// },
// {
//     id:"3",
// img:'/assets/images/logo.svg',
// name:'Vikki Star3',
// stars:3,
// desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
// postedDate:'January 15, 2023',

// },
// {
//     id:"4",
// img:'/assets/images/logo.svg',
// name:'Vikki Starr4',
// stars:4,
// desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
// postedDate:'January 15, 2023',


// }

// ]
// const []
return (
<div className='testimonials'>
    <div className="heading"> TESTIMONIALS</div>
    <div className='testi_slider'>
        

        <Slider {...settings}>
            {/* {testimonialsData?.map((item,id) => (
                <div className='testi_slide' key={id} >
                    <div>
                <div className='imgb'>
                    <img src={item?.img} alt="" />
                    <span>{item?.name}</span>
                </div>
                <hr/>
                <div className="stars">
                    <RatingDisplay ratingFromApi={item.stars} />
                </div>
                <p>{item.desc}</p>
                <span>{item.postedDate}</span>
                </div>
                </div>
            ))} */}

            {homeApiData?.testimonials?.map((item,id) => (
                <div className='testi_slide' key={id} >
                    <div>
                <div className='imgb'>
                    

                    <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
                    <span>{item?.name}</span>
                </div>
                <hr/>
                <div className="stars">
                    <RatingDisplay ratingFromApi={item.rating} />
                </div>
                <p>{item.description}</p>
                
                {/* <span>{item.postedDate}</span> */}
                <span>{convertToLocalTime(item?.updated_at)}</span>
                </div>
                </div>
            ))}

            
        </Slider>
    </div>
</div>
)
}