import React from "react";
// import { addItem} from "../../store/slices";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { percentageDisc } from "../../utils";

export default function ProductsHome({homeApiData}) {
  
  

  // const dispatch = useDispatch();



//   const handleAddCart = (e,item) => {
    
//          const newItem = {
//             productimg:item.image,
//             id: item.id,
            
//             name: item.prd_name,
//             qty: 1,
//             msr_unit: item.msr_unit,
//             unit: item.prd_qty,
//             price: item.discounted_price,
//           }; 
//                   dispatch(addItem(newItem));
//                   toast.success("Item Added Successfully",{toastId: "1"});;
// }
  
  
  

  return (
    <div>
      <div className="heading">CHOOSE YOUR PRODUCT</div>
      <ul className="product_listing_ul">
      {homeApiData?.hot_product.map((item, productId) => (
          <li className="products_listing_li" key={item.id}>
            <Link to={`/productdetail/${item.slug}`}>
            <div className="imgb">
            <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
            </div>

            <div className="text">
              <span>{item?.category}</span>
              <div className="name">{item?.prd_name}</div>
              <div className="star_reviews">

{item?.average_rating && 
                <div>
                  <i className="fa-solid fa-star"></i>
                  {Number(item?.average_rating).toFixed(1)}/5
                </div>
                }

 {item?.total_review &&
                <div>
                {item?.total_review} <span>Reviews</span>
                </div>
}
              </div>
            
              <div className="price_outer">
              <div className="disc_price">Rs. {item?.price}</div>
              <div className="price" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                  Rs. {item?.discounted_price}  <span style={{fontSize:'12px',color:'green',fontWeight:'500'}}>({percentageDisc(item?.price,item?.discounted_price)}% off)</span>
              </div>
              </div>

              
              {/* <span onClick={(e) => handleAddCart(e,item)} className="add_cart">
                Add to Cart
              </span> */}
              
              <span  className="add_cart" style={{pointerEvents:'none'}}>
                              Buy Now
                            </span>

            </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
