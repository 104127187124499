import React, { useState } from 'react'
import TagBar from '../TagBar'
import Header from '../Header'
import NavBar from '../NavBar'
import Footer from '../Footer'

export default function ChangePassword() {
  const [currentPassword,setCurrentPassword]=useState('');
  const [newPassword,setNewPassword]=useState('');
  const [confirmNewPassword,setConfirmNewPassword]=useState('');



  const passNotMatching = confirmNewPassword!== '' && newPassword !== confirmNewPassword
  

  return (
    <div className="wrapper account_wrapper">
      <div className="tag_line_outer">
        <TagBar />
      </div>
      <div className="header_outer">
        <div className="sec_container">
          <Header/>
        </div>
      </div>
      
        <NavBar />
        
      <div className="account_section_outer">
        <h1>Change Your Password</h1>
        <div className="sec_container">
        
        
        <div className="change_password_sec">
          <div className='form_field'>
            <label htmlFor="">Current Password</label>
            <input type="password" placeholder='Current Password' value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)}/>
          </div>
          <div className='form_field'>
            <label htmlFor="">New Password</label>
            <input type="password" placeholder='New Password' value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
          </div>
          <div className='form_field'>
            <label htmlFor="">Confirm New Password</label>
            <input type="password" placeholder='Confirm New Password' value={confirmNewPassword} onChange={(e)=>setConfirmNewPassword(e.target.value)}/>
          </div>

          {passNotMatching && <div style={{color:'red'}}>New Password Not Matching</div>}
          <div className="btn">
            <button type="submit">Change Password</button>
          </div>
        </div>
        
        
        </div>
        </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
