import React from 'react'

import Header from '../components/Header'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
// import { Link } from 'react-router-dom'

export default function Account() {
  return (
    <div className="wrapper account_wrapper">
      
      <div className="header_outer">
        <div className="sec_container">
          <Header/>
        </div>
      </div>
      
        <NavBar />
        
      <div className="account_section_outer">
        <h1>My Account</h1>
        <div className="sec_container">
        <div className="account_section_inner">
        <div>
        <h1>Manage Your Orders</h1>
        <ul className="">
            <li>
                {/* <Link to="/orders"> */}
            {/* <i className="fa-solid fa-list-check"></i> Past/Upcoming Orders */}
            {/* </Link> */}
            </li>
        </ul>
        </div>
        
        {/* <div>
            <h1>Manage Your Profile</h1>
            <ul className="">
            <li>
            <Link to="/changepassword">
            <i className="fa-solid fa-key"></i> Change Your Password
            </Link>
            </li>
            <li>
            <Link to="/myaddress">
            <i className="fa-regular fa-address-book"></i> Manage Your Addresses
            </Link>
            </li>
        </ul>
        </div> */}
        </div>
        </div>
        </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
