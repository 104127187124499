import React from "react";
// import { useDispatch } from "react-redux";
// import {  addItem } from "../../store/slices";
import { Link } from "react-router-dom";
import { percentageDisc } from "../../utils";
// import { toast } from "react-toastify";


export default function BestCookies({homeApiData}) {
  
  // const dispatch = useDispatch();

  
//   const handleAddCart = (e,item) => {
//     e.preventDefault(); 
//          const newItem = {
//             productimg:item.image,
//             id: item.id,
            
//             name: item.prd_name,
//             qty: 1,
//             msr_unit: item.msr_unit,
//             unit: item.prd_qty,
//             price: item.discounted_price,
//           }; 
//                   dispatch(addItem(newItem));
//                   toast.success("Item Added Successfully",{toastId: "1"});;
// }
  return (
        <div className="best_cookies">
          <div className="heading">BEST COOKIES TO BUY ONLINE</div>
          <ul className="product_listing_ul">
            <li className="best_prod">
              <img src="/assets/images/steptodown.com445552.png" alt="" />
              <span>Shop from our Best Products</span>
              <Link to="/products">View All</Link>
            </li>

            {homeApiData?.products?.slice(0, 2).map((item, productId) => (
          <li className="products_listing_li" key={item.id}>
            <Link to={`/productdetail/${item.slug}`}>
            <div className="imgb">
              {/* <img src={item?.img} alt="" /> */}
              <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
            </div>

            <div className="text">
              <span>{item?.category}</span>
              <div className="name">{item?.prd_name}</div>
              <div className="star_reviews">
                {item?.average_rating && 
                <div>
                  <i className="fa-solid fa-star"></i>
                  {/* {item?.average_rating.toFixed(1)}/5 */}
                  {Number(item?.average_rating).toFixed(1)}/5
                </div>
                }
                {item?.total_review &&
                <div>
                  {item?.total_review} <span>Reviews</span>
                </div>
}
              </div>
              {/* <span className="type">{item?.vegType}</span> */}
              <div className="price_outer">
                <div className="disc_price">Rs. {item?.price}</div>
                <div className="price" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                  Rs. {item?.discounted_price}  <span style={{fontSize:'12px',color:'green',fontWeight:'500'}}>({percentageDisc(item?.price,item?.discounted_price)}% off)</span>
                </div>
              </div>
              {/* <ul className="qty">
                {item?.variant.map((variantItem, variantId) => (
                  <li
                    key={variantId}
                    onClick={() => handleVariant(variantItem, productId, variantId)}
                    className={variantId === activeVariantId[productId] ? "active" : ""}
                  >
                    <div>{variantItem?.unit}</div>
                    <div>{variantItem?.msr_unit}</div>
                  </li>
                ))}
              </ul> */}
              {/* <span onClick={(e) => handleAddCart(e,item)} className="add_cart">
                Add to Cart
                
              </span> */}

<span  className="add_cart" style={{pointerEvents:'none'}}>
                              Buy Now
                            </span>

              
            </div>
            </Link>
          </li>
        ))}
          </ul>
        </div>
  );
}