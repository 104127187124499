import React from 'react'
// import { Link } from 'react-router-dom'
import Slider from "react-slick";

export default function Banner({homeApiData}) {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
      ]
    };

// const testimonialsData=[
// {
//     id:"1",
//     img:`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0].image}`,
// },
// {
//     id:"2",
//     img:`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0].image}`,
// }, 
// {
//     id:"3",
//     img:`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0].image}`,
// },
// {
//     id:"4",
//     img:`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0].image}`
// }

// ]
const testimonialsData=[
{
    id:"1",
    img:`/assets/images/Cookies-Banner.jpg`,
},
{
    id:"2",
    img:`/assets/images/Cookies-Banner.jpg`,
}, 
{
    id:"3",
    img:`/assets/images/Cookies-Banner.jpg`,
},
{
    id:"4",
    img:`/assets/images/Cookies-Banner.jpg`,
}

]
  
  return (
    
        <>
        {/* <img src={`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0]?.image}`} alt="" /> */}
                {/* <img src={`${REACT_APP_BASE_URL}${homeApiData?.banners[0].image}`} alt="" /> */}

                <Slider {...settings}>
             {testimonialsData?.map((item,id) => (
              <div key={id}>
            {/* <div className="banner_inner"> */}
                {/* <div className="banner_left">
                  <span>100% NATURAL</span>
                    <h1>EAT HEALTHY STAY HEALTHY</h1>
                    <Link to="/products">Shop All</Link>
                </div> */}
                
                
                
                
                {/* <div className="banner_right"> */}
                    <img src={item?.img} alt="" />
                    
                    {/* </div> */}
                
                

                
            </div>
            // </div>
                        ))} 

            

            
                        </Slider>
            </>
    
  )
}
