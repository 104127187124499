// slices.js
import { createSlice } from '@reduxjs/toolkit';



export const couponCode = createSlice({
  name:'couponData',
  initialState:{},
  reducers:{
    setCouponData:(state,action)=>{
      return action.payload;
    }
  }
})

export const { setCouponData } = couponCode.actions;
export const couponData = (state) => state.couponData;
// console.log(couponData,"couponData")



export const productListing= createSlice({
  name: 'listingData',
  initialState: {
    value: [],
  },
  reducers: {
    setListingData: (state, action) => {
      state.value = action.payload;
    },
    
  },
});


export const { setListingData } = productListing.actions;
export const listingData = (state) => state.listingData.value;



export const loggedInUser = createSlice({
  name:'isLoggedInUser',
  initialState:null,
  reducers:{
    setIsLoggedInUser: (state, action) => state = action.payload
  }
});


export const {setIsLoggedInUser}= loggedInUser.actions;
// export const isLoggedInUser =(state)=>(state.isLoggedInUser.value);



export const accessToken = createSlice({
  name:'isaccessToken',
  initialState:null  ,
  reducers:{
    setAccessToken: (state, action) => state = action.payload
  }
});


export const {setAccessToken}= accessToken.actions;






export const cartListing = createSlice({
  name: 'cartData',
  initialState: {
     items: [], 
  },
  reducers: {
    
    addItem: (state, action) => {
      const {  id, qty } = action.payload;
      const existingItem = state.items.find(item =>  item.id===id);
      if (existingItem) {
        
        existingItem.qty += qty;    
      } else {
        state.items.push(action.payload);
      }
    },
    updateQuantity: (state, action) => {
      const { id, qty } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        existingItem.qty = qty;
      }
    },
    removeItem: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.filter(item => item.id !== id);
    },
    clearCart:(state)=>{
      // state.items.length=0
      state.items=[]
    }
    
  },
});

export const { addItem,removeItem,clearCart,updateQuantity } = cartListing.actions;
// export const cartData = (state) => state.cartListing.value;

export default cartListing.reducer;