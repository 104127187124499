import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Header from "../Header";
import NavBar from "../NavBar";
import Banner from "./Banner";
import Advantages from "./Advantages";
import BestCookies from "./BestCookies";
import Testimonials from "./Testimonials";

import FirstOrder from "./FirstOrder";
import ProductsHome from "./productsHome";

import Footer from "../Footer";
import TagBar from "../TagBar";
import api from "../../axios/api";
import Loader from "../Loader";
// import PopUp from "./PopUp";

export default function HomePage() {
  const location = useLocation();
  const [homeApiData, setHomeApiData] = useState("");


  const HomePageData = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_BASE_URL}home`);
     

      setHomeApiData(response?.data?.data);
      // Handle the response data as needed, e.g., set it to state
    } catch (error) {
      console.error("API error:", error);
      // Handle the error, e.g., set an error state or show an error message
    }
  };

  useEffect(() => {
    HomePageData();
    // setHomePopUp(true)
  }, []);

  // const [homePopUp,setHomePopUp]=useState(false)
  return (
    <>
{/* 
    {homePopUp && <PopUp setHomePopUp={setHomePopUp}/>} */}
      {homeApiData ? ( 
        <div className="wrapper home_page">
          <div className="tag_line_outer">
            <TagBar homeApiData={homeApiData} />
          </div>

          <div className="header_outer">
            <div className="sec_container">
              <Header />
            </div>
          </div>

          <NavBar />

          <div className="banner_outer">
            {/* <div className="sec_container"> */}
              <Banner homeApiData={homeApiData} />
            {/* </div> */}
          </div>
          <div className="advantages_outer">
            <div className="sec_container">
              <Advantages />
            </div>
          </div>
          <div className="best_cookies_outer">
            <div className="sec_container">
              <BestCookies homeApiData={homeApiData} />
            </div>
          </div>
          <div className="testimonials_outer">
            <div className="sec_container">
              <Testimonials homeApiData={homeApiData} />
            </div>
          </div>
          <div className="product_listing_outer">
            <div className="sec_container">
              <ProductsHome homeApiData={homeApiData} />
            </div>
          </div>
          <div className="get_free_delivery_outer">
            <div className="sec_container">
              <div className="get_free_inner">
                <div>
                  <div className="heading">GET FREE HOME DELIVERY</div>
                  <span>
                    On Order Above <b>Rs. 500!</b>
                  </span>
                </div>
                <Link to="/products" className={location.pathname === '/products' ? 'active' : ''}>SHOP NOW</Link>
              </div>
            </div>
          </div>
          <div className="how_to_order_outer">
            <div className="sec_container">
              <div className="heading">HOW TO ORDER ONLINE</div>
              <p>
                Ordering online from Fidalgo is easy. We are proud to have made
                the process accessible across multiple platforms and simple to
                understand, meaning that more people can come to us to buy their
                groceries online.
              </p>
              <ul>
                <li>
                  <img src="/assets/images/order_1.svg" alt="" />
                  <b>1</b>
                  <span>REGISTER</span>
                  <p>
                    Sign up for an account with us. This is quick and simple. We
                    don’t require any more details from you than the bare
                    minimum needed for you to place an order and get your
                    product delivered.
                  </p>
                </li>
                <li>
                  <img src="/assets/images/order_2.svg" alt="" />
                  <b>2</b>
                  <span>SHOP</span>
                  <p>
                    Decide on what you want to purchase. We stock a wide range
                    of edibles,flowers , concentrates and mushrooms there is
                    bound to be something for everyone.
                  </p>
                </li>
                <li>
                  <img src="/assets/images/order_3.svg" alt="" />
                  <b>3</b>
                  <span>MAKE PAYMENT</span>
                  <p>
                    Pay securely. Our site boasts sturdy protection certificates
                    to keep your card details and related data safe.
                  </p>
                </li>
                <li>
                  <img src="/assets/images/order_4.svg" alt="" />
                  <b>4</b>
                  <span>RELAX</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum atque quidem suscipit est, similique nesciunt!
                    Pariatur, debitis recusandae suscipit consequuntur culpa
                    deserunt vel repudiandae ab laboriosam repellendus earum a
                    architecto.
                  </p>
                </li>
              </ul>

              <Link to="/products">Order Now</Link>
            </div>
          </div>
          <div className="first_order_outer">
            <div className="sec_container">
              <FirstOrder
                heading={"UNLOCK 20% OFF YOUR FIRST ORDER"}
                subheading={"Use Code - FIRST20"}
              />
            </div>
          </div>
          <div className="footer_outer">
            <div className="sec_container">
              <Footer />
            </div>
          </div>
        </div>
      ) : ( 
       <Loader />
      // <div>
      //   <h1>The Page is not working</h1>
      // </div>
   )} 
    </>
  );
}
